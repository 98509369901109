import {toUrlEncoded} from "../util";
import jwtDecode from "jwt-decode";
import SessionManager from "./SessionManager";

/**
 * This class handles the communication between cognito and our application. It manages the session
 * data required to perform the oauth handshake and token exchange. It expects the auth service
 * to provide it with a set of configuration needed to communicate with cognito.
 */
export class CognitoApi {
  #refreshListener = null;
  #refreshTimer = null;
  #session = null;
  #config = null;

  constructor(apiConfig = {}) {
    console.debug("CognitoApi: initialise")
    this.#session = new SessionManager();
    this.#config = {...apiConfig};
    this.#startRefreshTimer()
  }

  /**
   * This directs the user to cognito login or sign up page
   *
   * @param isSignup directs to signup page if true, otherwise login page
   * @param preAuthUri location user will be returned to after authentication
   * @returns {Promise<boolean>}
   */
  async authorize(isSignup = false, preAuthUri = null) {
    const {clientId, redirectUri, scopes} = this.#config

    // initialise session values, before redirecting to oauth flow
    const {codeChallenge, codeChallengeMethod} = await this.#session.newSession(preAuthUri)
    this.#triggerSessionListener();

    const query = {
      clientId,
      redirectUri,
      codeChallenge,
      codeChallengeMethod,
      scope: scopes.join(' '),
      responseType: 'code',
      // ...(audience && {audience}) currently not used
    }

    // determine endpoint
    const endpoint = isSignup === false ? this.#getAuthorizationEndpoint() : this.#getRegistrationEndpoint();
    const queryString = toUrlEncoded(query);
    const url = `${endpoint}?${queryString}`

    // Responds with a 302 redirect
    window.location.assign(url);
    return true;
  }

  /**
   * Clears the session data and performs a logout
   * @param globalLogout
   * @returns {Promise<boolean>}
   */
  async logout(globalLogout) {
    this.#session.clearSession();
    this.#triggerSessionListener();
    if (globalLogout) {
      const {clientId, redirectUri} = this.#config;

      const endpoint = this.#getLogoutEndpoint();
      const query = {
        client_id: clientId,
        post_logout_redirect_uri: redirectUri
      }
      const queryString = toUrlEncoded(query);
      const url = `${endpoint}?${queryString}`

      // Responds with a 302 redirect
      window.location.replace(url)
    }
    return true;
  }

  /**
   * Performs a fetch request to exchange the code for cognito authentication tokens
   *
   * @param code
   * @returns {Promise<any>}
   */
  async getAuthorizationToken(code) {
    try {
      const {clientId, redirectUri} = this.#config;

      // retrieve pkce values from session 
      const codeVerifier = this.#session.getPkceCodeVerifier()

      const endpoint = this.#getTokenEndpoint();
      const payload = {
        clientId,
        redirectUri,
        code,
        codeVerifier,
        grantType: 'authorization_code',
        // clientSecret // only used for testing
      };

      const data = await this.#fetchRequest(endpoint, payload);

      // decorate token with expiry time
      data.expires_at = this.#calculateTokenExpiry(data.expires_in)

      // start session and refresh timer
      this.#session.storeSession(data)

      // initialise session
      this.#startRefreshTimer();

      return data;
    } finally {
      this.#triggerSessionListener();
    }
  }

  /**
   * Performs a fetch request to exchange refresh token for valid authentication tokens
   *
   * @returns {Promise<any>}
   */
  async refreshAuthorizationToken() {
    try {
      console.debug("CognitoApi: refresh")
      const {clientId, redirectUri} = this.#config;

      // get current refresh token
      const {authTokens} = this.#session.getSessionData();
      const {refresh_token} = authTokens;

      const endpoint = this.#getTokenEndpoint();
      const payload = {
        clientId,
        redirectUri,
        grantType: 'refresh_token',
        refresh_token: refresh_token
      };

      // refresh and decorate token with expiry and refresh token
      const data = await this.#fetchRequest(endpoint, payload);

      // decorate token with expiry time and original refresh token
      data.expires_at = this.#calculateTokenExpiry(data.expires_in);
      data.refresh_token = refresh_token;

      // store new session token
      this.#session.storeSession(data)

      return data;
    } catch (e) {
      // refresh token probably expired lets log out
      this.#session.clearSession();
      throw e;
    } finally {
      this.#triggerSessionListener();
    }
  }

  /**
   * Gets the stored session data and converts it for use by our application
   */
  getSession() {
    const {authTokens, preAuthUri} = this.#session.getSessionData();

    // check token exists an is valid (not expired)
    const now = new Date().getTime()
    const {id_token, expires_at} = authTokens;
    const authenticated = id_token ? now < expires_at : false;

    if (authenticated) {
      const userInfo = jwtDecode(id_token);
      return {
        userInfo,
        authenticated,
        token: id_token,
        redirectUri: preAuthUri,
      };
    } else {
      return {
        token: null,
        userInfo: {},
        authenticated,
        redirectUri: preAuthUri,
      };
    }
  }

  /**
   * Adds callback for session updates (token refresh)
   *
   * @param listener
   */
  setSessionListener(listener) {
    this.#refreshListener = listener;
  }

  /**
   * Clear refresh timers and remove listener
   */
  cleanup() {
    this.setSessionListener(null);
  }


  /**
   * If we have a session listener, we trigger it when we update token states
   */
  #triggerSessionListener() {
    // fire session listener to update state across app
    if (typeof this.#refreshListener === "function") {
      const session = this.getSession();
      this.#refreshListener(session)
    }
  }

  /**
   * Used to automatically refresh cognito tokens
   */
  #startRefreshTimer() {
    if (this.#config.autoRefresh) {
      const {authTokens} = this.#session.getSessionData();
      const {refresh_token, expires_at} = authTokens;
      if (refresh_token && expires_at) {
        const now = new Date().getTime()
        const timeToExpire = expires_at - now
        this.#armRefreshTimer(timeToExpire)
      }
    }
  }

  /**
   * Process that will refresh tokens and reschedule the next call
   * @param duration
   */
  #armRefreshTimer(duration) {
    this.#disarmRefreshTimer();
    this.#refreshTimer = window.setTimeout(async () => {
      // get refresh token and call refresh
      await this.refreshAuthorizationToken();

      // restart timer after refresh
      this.#startRefreshTimer();
    }, duration);
    console.debug("CognitoApi: auto-refresh", duration > 0 ? Math.trunc(duration / 1000 / 60) : "now");
  }

  #disarmRefreshTimer() {
    if (this.#refreshTimer) {
      clearTimeout(this.#refreshTimer)
    }
  }

  /**
   * Calculates expiry for token used for automatic refresh process
   *
   * @param expiresIn token expiry from cognito
   * @returns {number}
   */
  #calculateTokenExpiry(expiresIn) {
    if (typeof expiresIn === "number") {
      const {refreshSlack = 60} = this.#config
      const now = new Date().getTime()
      return now + (expiresIn - refreshSlack) * 1000
    }
    return -1;
  }

  async #fetchRequest(endpoint, payload, {contentType} = {}) {
    const response = await fetch(endpoint, {
      body: toUrlEncoded(payload),
      method: 'POST',
      headers: {
        'Content-Type': contentType || 'application/x-www-form-urlencoded'
      }
    })

    // TODO: consider doing this manually, response.text() then try to parse as json;
    const data = await response.json();

    if (!response.ok || data.error) {
      const responseData = data && data.error ? JSON.stringify(data) : undefined;
      console.log(`Fetch Failed: ${response.status} - ${response.statusText}`, responseData)
      // TODO: consider handling response errors (!response.ok) better
      throw new Error(`Authorization Failed: ${data.error}`);
    }


    return data
  };

  #getTokenEndpoint() {
    const {provider} = this.#config;
    return `${provider}/oauth2/token`;
  }

  #getAuthorizationEndpoint() {
    const {provider} = this.#config;
    return `${provider}/oauth2/authorize`;
  }

  #getRegistrationEndpoint() {
    const {provider} = this.#config;
    return `${provider}/signup`;
  }

  #getLogoutEndpoint() {
    const {provider} = this.#config;
    return `${provider}/logout`;
  }
}