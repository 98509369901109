import {useAuth} from "./auth/CognitoAuthContext";
import config from '../aws-config.json'
import {useMemo} from "react";

class ApiService {
  #session = null;

  constructor() {
    console.debug("ApiService init");
  }

  updateSession(session) {
    this.#session = session;
  }

  async resourcesGet() {
    return await this.#get('resources');
  }

  async sendAction(payload) {
    return await this.#post('action', payload);
  }

  async sendMessage(payload) {
    return await this.#post('contact', payload);
  }

  async checkAuth(payload) {
    return await this.#post('authenticated', payload);
  }

  async usersGet(payload) {
    return await this.#post('manage', payload);
  }

  /**
   * Used when wanting to test user loading experience
   * @param delay
   * @returns {Promise<unknown>}
   */
  async fakeAction(delay = 5000) {
    return new Promise((resolve) => setTimeout(() => {
      resolve({code: 200, success: true});
    }, delay))
  }

  async #post(path, data) {
    if (this.#session?.authenticated) {
      try {
        const headers = this.#createHeaders();
        const response = await fetch(`${config.api.url}/${path}`, {
          headers,
          method: 'POST',
          body: JSON.stringify(data)
        });

        if (!response.ok) {
          // console.log(`response: ${response.status}`);
          return {code: response.status, success: false};
        }

        return {code: 200, success: response.ok, data: await response.json()};
      } catch (e) {
        console.error(`request failed: ${path}`, e);
        return {code: 500, success: false};
      }
    } else {
      console.debug("User not authenticated");
      return {};
    }
  }

  async #get(path) {
    if (this.#session?.authenticated) {
      try {
        const headers = this.#createHeaders();
        const response = await fetch(`${config.api.url}/${path}`, {
          headers,
          method: 'GET'
        });

        if (!response.ok) {
          // console.log(`response: ${response.status}`);
          return {code: response.status, success: false};
        }

        return {code: 200, success: response.ok, data: await response.json()};
      } catch (e) {
        console.error(`request failed: ${path}`, e);
        return {code: 500, success: false};
      }
    } else {
      console.debug("User not authenticated");
      return {};
    }
  }

  #createHeaders() {
    return {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${this.#session?.token || "missing"}`
    };
  }

  // used to ensure react only creates one auth service
  static #instance;

  static getInstance(session) {
    if (!this.#instance) {
      this.#instance = new ApiService();
    }
    // ensure session matches
    this.#instance.updateSession(session)
    return this.#instance;
  }
}

export const useApi = () => {
  const session = useAuth();
  return useMemo(() => ApiService.getInstance(session), [session]);
};
