import React, {useState} from 'react';

const DownloadButton = ({onClick, file, children}) => {
  const [downloading, setDownloading] = useState(false);

  const downloadClick = async () => {
    setDownloading(true);
    await onClick(file)
    setDownloading(false);
  };

  return (
    <>
      <button onClick={downloadClick}
              className="font-medium flex gap-2 items-center text-proof-blue hover:underline">
        {downloading && (
          <span className="relative flex h-3 w-3">
            <span  className="absolute inline-flex h-full w-full rounded-full opacity-75 animate-ping bg-proof-blue"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-proof-blue"></span>
          </span>
        )}
        {children}
      </button>
    </>
  )
}
export default DownloadButton;
