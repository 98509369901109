import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from "clsx";

const FormatText = ({textKey, colours, classNames}) => {
  const { t } = useTranslation();
  const text = t(textKey);
  
  return (
    <div className={classNames}>
      {formatUnderline(text, colours)}
    </div>
  );
}

const formatUnderline = (text, colours) => {
  const caps = text.match(/(_[A-Z]_)/g);
  if (caps && caps.length > 0) {
    const capitols = text.split(/(_[A-Z]_)/g);
    let partIndex = 0;
    const listItems = capitols.map((part, index) => {
      if (part && part.match(/(_[A-Z]_)/)) {
        const colour = colours ? colours[partIndex++%colours.length] : null;
        return (<span key={index} className={clsx("underline", colour)}>{part.replaceAll('_', '')}</span>)
      } else
        return part;
    });
    return listItems;
  } else {
    return (text);
  }
}

export default FormatText;
