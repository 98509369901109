import React, {createContext, useState, useContext, useRef, useEffect} from 'react';

export const createAppState = (state = {}) => {
  const {isMenuOpen, toggleMenu} = state;
  return {
    isMenuOpen: isMenuOpen || false,
    toggleMenu: toggleMenu || (() => {}),
  }
};

// Create the context
const AppContext = createContext(createAppState());

// Create a provider component
export const ApplicationProvider = ({ children }) => {
  
  // initialise menu state from local storage
  const [isMenuOpen, setIsMenuOpen] = useState(() => {
    const isOpen = localStorage.getItem('menuState');
    return isOpen === "true";
  });
  
  const toggleMenu = () => {
    localStorage.setItem('menuState', `${!isMenuOpen}`);
    setIsMenuOpen(prevState => !prevState);
  };

  return (
    <AppContext.Provider value={{ isMenuOpen, toggleMenu }}>
      {children}
    </AppContext.Provider>
  );
};

// Custom hook for easy context consumption
export const useApp = () => {
  return useContext(AppContext);
};

// custom hook for click outside
export const useClickOutside = (handler) => {
  const ref = useRef();

  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or its descendants
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [handler]);

  return ref;
};