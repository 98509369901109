import {useAuth} from "./auth/CognitoAuthContext";
import AccessDenied from "../pages/AccessDenied";
import {Navigate, Outlet} from 'react-router-dom'
import Loading from "../pages/Loading";
import {checkUserPermission} from "./util";

export default function PrivateRoutes({groups = []}) {
  const {initialised, authenticated, termsAccepted, userInfo} = useAuth();

  if (initialised === false) {
    return <Loading/>; // wait until we have initialised authentication services
  }

  // Determine the routing based on user's status
  if (authenticated === true && termsAccepted !== true) {
    return <Navigate to="/terms" />;
  }
  
  // check if user has permission
  const isAllowed = checkUserPermission(authenticated, groups, userInfo);

  return (
    isAllowed ? <Outlet/> : <AccessDenied groups={groups}/>
  );
}
