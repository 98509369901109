import {extractCodeFromUrl, removeCodeFromUrl} from "../../services/util";
import {useAuth} from "../../services/auth/CognitoAuthContext";
import PageLayout from "../../components/PageLayout";
import {Trans} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import Loading from "../Loading";

export default function Authorize() {
  const {initialised, redirectUri, verify} = useAuth();
  const navigate = useNavigate(); // Initialize useHistory hook

  const [error, setError] = useState(false);

  useEffect(() => {
    async function verifyCode() {
      try {
        const code = extractCodeFromUrl();
        const isValidCode = await verify(code);
        if (isValidCode) {
          // remove code from url and redirect
          removeCodeFromUrl();
          navigate(redirectUri ?? "/");
        } else {
          setError(true);
        }
      } catch (error) {
        setError(true);
      }
    }

    if (initialised && redirectUri) {
      // only verify once auth service ready
      verifyCode();
    }
  }, [initialised, redirectUri]) // eslint-disable-line

  if (error) {
    // show an error page if authentication fails for some reason
    return (<PageLayout title="authorize">
      <section aria-labelledby="section-heading rounded-lg">
        <div className="relative px-6 py-32 sm:px-12 sm:py-40 lg:px-16 rounded-lg">
          <div aria-hidden="true"
               className="absolute inset-0 bg-gray-900 dark:bg-gray-600 bg-opacity-50 rounded-lg"></div>
          <div className="relative mx-auto flex max-w-full flex-col items-center text-center">
            <h2 id="section-heading" className="text-lg font-bold tracking-tight text-white lg:text-2xl">
              <Trans i18nKey="400.title">
                400 - Authentication Error
              </Trans>
            </h2>

            <p className="text-sm text-primary font-light mt-4">
              <Trans i18nKey="400.text">
                An error occurred please, if this persists <a className="font-medium underline hover:opacity-50"
                                                              href="/contact">contact us</a>
              </Trans>
            </p>
          </div>
        </div>
      </section>
    </PageLayout>)
  }
  return <Loading/>;
}