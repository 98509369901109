import {useAuth} from "../services/auth/CognitoAuthContext";
import {Trans, useTranslation} from "react-i18next";
import React from "react";

import PageLayout from "../components/PageLayout";

export default function AccessDenied({groups}) {
  const {authenticated} = useAuth();
  return (
    <PageLayout title="Login Required">
      <section aria-labelledby="section-heading rounded-lg">
        <div className="relative px-6 py-32 sm:px-12 sm:py-40 lg:px-16 rounded-lg">
          <div aria-hidden="true" className="absolute inset-0 bg-gray-900 dark:bg-gray-600 bg-opacity-50 rounded-lg"></div>
          <div className="relative mx-auto flex max-w-full flex-col items-center text-center">
            {authenticated && groups.length ? (<PermissionDenied />) : <LoginRequired />}
          </div>
        </div>
      </section>
    </PageLayout>
  )
}

const LoginRequired = () => {
  const {t} = useTranslation();
  const {login, register} = useAuth();
  return (
    <>
      <h2 id="section-heading" className="text-lg font-bold tracking-tight text-white lg:text-2xl">
        {t('403.title')}
      </h2>

      <button onClick={() => login()} className="mt-4 button button-white">{t('proof.loginButton')}</button>
      <p className="text-sm text-primary font-light mt-4">
        <Trans i18nKey="403.signup">
          Don’t have an account? <button onClick={() => register(true)} rel="noreferrer"
                                         className="font-medium underline hover:opacity-50">Register</button>
        </Trans>
      </p>
    </>
  );
}

const PermissionDenied = () => {
  return (
    <>
      <h2 id="section-heading" className="text-lg font-bold tracking-tight text-white lg:text-2xl">
        <Trans i18nKey="401.title">
          401 - Permission Denied
        </Trans>
      </h2>

      <p className="text-sm text-primary font-light mt-4">
        <Trans i18nKey="401.text">
          You do not have permission to view this page, return to <a className="font-medium underline hover:opacity-50" href="/">home</a>
        </Trans>
      </p>
    </>
  )
}