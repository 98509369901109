import {createContext, useContext} from "react";

export const createState = (session = {}) => {
  const {token, redirectUri, authenticated, userInfo} = session;
  return {
    termsAccepted: userInfo ? (/(true|yes)/i).test(userInfo["custom:terms"]) : undefined,
    authenticated: authenticated,
    redirectUri: redirectUri,
    userInfo: userInfo || {},
    token: token,
  }
};

// Initial authentication state
export const initialAuthState = createState()

// Create authentication context
export const AuthContext = createContext(initialAuthState);

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}