import {CognitoApi} from "./CognitoApi";
import {errorService} from "../ErrorService";

export class AuthService {
  #cognitoApi = null;
  
  constructor(apiConfig) {
    console.debug("AuthService: initialise")
    this.#cognitoApi = new CognitoApi(apiConfig);
  }
  
  /**
   * Perform a redirect to cognito authentication service.
   * 
   * @param isSignup
   * @param redirectUri
   * @returns {Promise<boolean>}
   */
  async authorize(isSignup = false, redirectUri = null) {
    try {
      await this.#cognitoApi.authorize(isSignup, redirectUri);
      return true;
    } catch (e) {
      errorService.captureException(e, "AuthService.authorize", {isSignup, redirectUri});
      return false;
    }
  }

  /**
   * Verify code returned from cognito is valid and exchange for auth token
   * 
   * @returns {Promise<boolean>}
   */
  async verify(code) {
    if (code !== null) {
      try {
        await this.#cognitoApi.getAuthorizationToken(code);
        return true;
      } catch (e) {
        errorService.captureException(e, "AuthService.verify", {code});
        return false;
      }
    } else {
      errorService.captureException(new Error("Missing authorization code"), "AuthService.verify");
      return false;
    }
  }

  /**
   * Will clear the users current session and sign them out of our application.
   * 
   * @param globalLogout if true, will attempt to sign out cognito session also
   * @returns {Promise<boolean>}
   */
  async logout(globalLogout = false) {
    try {
      await this.#cognitoApi.logout(globalLogout);
      return true;
    } catch (e) {
      errorService.captureException(e, "AuthService.logout", {globalLogout});
      return false;
    }
  }

  /**
   * Get new tokens from the server, updating any local tokens
   * 
   * @returns {Promise<boolean>}
   */
  async refresh() {
      try {
        await this.#cognitoApi.refreshAuthorizationToken();
        return true;
      } catch (e) {
        errorService.captureException(e, "AuthService.refresh");
        return false;
      }
  }
  
  setSessionListener(listener) {
    this.#cognitoApi.setSessionListener(listener)
  }
  
  cleanup() {
    this.#cognitoApi.cleanup();
  }
  
  /**
   * Get current session data
   * @returns {*}
   */
  getSession() {
    return this.#cognitoApi.getSession();
  }
  
  // used to ensure react only creates one auth service
  static #instance;
  static getInstance(apiConfig) {
    if(!this.#instance) {
      this.#instance = new AuthService(apiConfig);
    }
    return this.#instance;
  }
}
