class ThemeService {
  refresh() {
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark')
      document.documentElement.classList.remove('light')
    } else {
      document.documentElement.classList.add('light')
      document.documentElement.classList.remove('dark')
    }
  }

  useDark() {
    localStorage.theme = 'dark';
    this.refresh();
  }

  useLight() {
    localStorage.theme = 'light';
    this.refresh();
  }

  useSystemDefault() {
    localStorage.removeItem('theme');
    this.refresh();
  }
  
  value() {
    return localStorage.theme || "system";
  }
}

const themeService = new ThemeService();

export default themeService;