export const defaultFormValues = (survey, userInfo) => {
  const formValues = {};
  survey.pages.forEach((page) => {
    page.questions.forEach(question => {
      if (typeof question?.default === "string") {
        const [type, key] = question.default.split(":");
        if (type === 'userInfo' && userInfo[key]) {
          formValues[question.id] = userInfo[key];
        } else if(key === undefined) {
          // simple string default
          formValues[question.id] = type;
        }
      }
    })
  })
  return formValues;
}