import {useTranslation} from 'react-i18next';
import {NavLink, useLocation} from "react-router-dom";
import React from "react";
import clsx from "clsx";

import {ThemeButton} from "./ThemeButton";
import {ProfileMenu} from "./ProfileMenu";
import Container from "./Container";
import {useApp} from "../services/AppContext";
import SvgIcon from "./SvgIcon";

const PageNavigation = ({links, useColours}) => {
  const {t} = useTranslation();
  const {isMenuOpen, toggleMenu} = useApp()
  const location = useLocation();
  const isHomePath = location.pathname === "/";
  return (
    <header>
      <nav>
        <Container className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 z-0">
          <div>
            <div className={clsx("flex items-center justify-between mb-2", isHomePath ? "h-16 lg:h-20" : "h-12 lg:h-16")}>
              {/* Desktop Menus */}
              <div className="hidden lg:flex lg:flex-1 lg:items-center">
                <NavLink to="/">
                  <span className="sr-only">{t('proof.title')}</span>
                  <img className={clsx("w-auto", isHomePath ? "h-14" : "h-8")} src="/images/proof-logo.png" alt={t('proof.title')}/>
                </NavLink>
              </div>

              {/* Mobile Menus */}
              <div className="flex flex-1 items-center lg:hidden relative">
                <button onClick={toggleMenu} type="button" className="-ml-2 p-2 hover:bg-gray-600 hover:bg-opacity-50 rounded-full">
                  <span className="sr-only">Open menu</span>
                  <SvgIcon type="mobile" />
                </button>
              </div>

              <div className="hidden h-8 lg:flex">
                <div className="inset-x-0 bottom-0 px-4">
                  <div className="flex h-full justify-center space-x-6">
                    {Array.isArray(links) && links.length > 0 ? links.map((link, index) => (
                      <NavLink key={index} to={link.path}
                               className={({isActive}) => clsx("navigation-link", isActive && "navigation-active", isActive && !useColours && "border-black dark:border-white", useColours && isActive && link.colour)}>
                        {link.title}
                      </NavLink>)) : undefined}
                  </div>
                </div>
              </div>

              <a href="/" className="lg:hidden">
                <span className="sr-only">{t('proof.title')}</span>
                  <img className={clsx("w-auto", isHomePath ? "h-14" : "h-8")} src="/images/proof-logo.png" alt={t('proof.title')}/>
              </a>

              <div className="flex flex-1 h-8 items-center justify-end z-20">
                <ThemeButton classNames="ml-4"/>
                <ProfileMenu classNames="ml-4"/>
              </div>
            </div>
            
            <div className={clsx("flex justify-center space-x-6 transition-all delay-150 duration-300 lg:hidden", isMenuOpen ? "h-8 mb-2" : "h-0 opacity-0 overflow-hidden")}>
              {Array.isArray(links) && links.length > 0 ? links.map((link, index) => (
                <NavLink key={index} to={link.path}
                         className={({isActive}) => clsx("navigation-link mobile z-0", useColours && link.colour, isActive && "navigation-active")}>
                  {link.title}
                </NavLink>)) : undefined}
            </div>

          </div>
        </Container>
      </nav>
    </header>
  );
}
export default PageNavigation;
