import PageLayout from "../components/PageLayout";
import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";
import React from "react";

export default function NotFound() {
  const {t} = useTranslation()
  return (
    <PageLayout title="Not Found - 404" isFullWidth={true}>
      <div className="relative bg-gray-800 px-6 sm:px-12 transition-[padding] py-4 sm:py-10 md:py-15 lg:py-20 lg:px-16">
        {/*<CoverImage imageUrl="https://images.unsplash.com/photo-1606092195730-5d7b9af1efc5?q=80"/>*/}
        <div aria-hidden="true" className="absolute inset-0 bg-proof-blue"></div>
        <div aria-hidden="true" className="absolute inset-0 bg-gray-900 bg-opacity-80"></div>
        <div className="relative mx-auto flex max-w-full flex-col items-center text-center">
          {/*<img className="object-cover h-24 mb-10" src="/images/proof-logo.png" alt={t('proof.title')}/>*/}
          <h1>{t("404.title")}</h1>
          <p className="mt-4">{t("404.text")}</p>
          <Link className="button button-purple self-center mt-8" to='/'>{t("404.linkText")}</Link>
        </div>
      </div>
    </PageLayout>
  )
}