import {useTranslation} from "react-i18next";
import {useAuth} from "../services/auth/CognitoAuthContext";
import React, {useEffect, useState} from "react";
import {defaultFormValues} from "../forms/defaults";
import clsx from "clsx";
import SvgIcon from "./SvgIcon";

export default function SurveyPage({survey, clearOnRefresh, onSubmit}) {
  const {t} = useTranslation();
  const {initialised, userInfo} = useAuth();

  const [submitting, setSubmitting] = useState(false);
  const [surveyId] = useState(survey?.id || "survey-form");
  const [pageIndex, setPageIndex] = useState(0);
  const [pages] = useState(survey?.pages || []);
  const [errors, setErrors] = useState({});

  if (clearOnRefresh === true) {
    // clear localStorage on page refresh / revisit
    localStorage.removeItem(`${surveyId}`);
  }

  // initialise formValues from localStorage
  const [formValues, setFormValues] = useState(() => {
    const savedValues = localStorage.getItem(`${surveyId}`);
    return savedValues ? JSON.parse(savedValues) : {};
  });

  useEffect(() => {
    if(initialised) {
      // save default values if object empty
      if (Object.keys(formValues).length === 0) {
        const defaultValues = defaultFormValues(survey, userInfo);
        // Save formValues to localStorage whenever they change
        localStorage.setItem(`${surveyId}`, JSON.stringify(defaultValues));
        setFormValues(defaultValues);
      } else {
        // Save formValues to localStorage whenever they change
        localStorage.setItem(`${surveyId}`, JSON.stringify(formValues));
      }
    }
  }, [initialised, userInfo, formValues]); // eslint-disable-line

  const handleInputChange = ({event, /*question, */option, options}) => {
    const {name, value, type, checked} = event.target;
    switch (type) {
      case "checkbox":
        // clears input field value for unchecked option(s)
        if (checked === false && formValues[option.input]) {
          delete formValues[option.input];
        }

        // add or remove checkbox from form value array
        const values = Array.isArray(formValues[name]) ? formValues[name] : [];
        setFormValues({
          ...formValues,
          [name]: checked ? [...values, value] : values.filter(v => v !== value),
        });
        break;

      case "radio":
        // clears input field value for unselected option(s)
        const inputOptions = options.filter(o => o.name !== option.name && o.input);
        for (const opt of inputOptions) {
          delete formValues[opt.input];
        }

        // update radio form value
        setFormValues({
          ...formValues,
          [name]: value,
        });
        break;

      default:
        // update form values
        setFormValues({
          ...formValues,
          [name]: value,
        });
    }
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = {};

    // validates current page only
    const page = pages[pageIndex];

    // Validate all required fields
    page.questions.forEach(question => {
      if (question.required && !formValues[question.id]) {
        newErrors[question.id] = 'This field is required';
        valid = false;
      }
    });

    // Custom validation for checkbox group
    page.questions.filter(question => question.type === "checkbox").forEach(question => {
      const selectedOptions = (formValues[question.id] || []);
      if (question.required && selectedOptions.length === 0) {
        newErrors[question.id] = 'Please select at least one option';
        valid = false;
      }
      question.options.forEach(option => {
        if (option.input && selectedOptions.includes(option.value) && !formValues[option.input]) {
          newErrors[option.input] = 'This field is required';
          valid = false;
        }
      });
    });

    // Custom validation for radio group
    page.questions.filter(question => question.type === "radio").forEach(question => {
      const selectedOption = formValues[question.id] || [];
      if (question.required && selectedOption.length === 0) {
        newErrors[question.id] = 'Please select at least one option';
        valid = false;
      }
      question.options.forEach(option => {
        if (option.input && selectedOption === option.value && !formValues[option.input]) {
          newErrors[option.input] = 'This field is required';
          valid = false;
        }
      });
    });

    // Custom validation for agreement radio groups
    page.questions.filter(question => question.type === "agreement").forEach(question => {
      if (question.required && formValues[question.id] !== "agree") {
        newErrors[question.id] = 'You must agree to access PROOF resources';
        valid = false;
      }
    });

    setErrors(newErrors);
    return valid;
  };

  const handleNext = () => {
    if (validateForm() && pageIndex < survey.pages.length) {
      setPageIndex(pageIndex + 1);
    }
    return false;
  };

  const handlePrevious = () => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1);
    }
    return false;
  };

  const handleSubmit = async () => {
    if (!submitting && validateForm(pageIndex)) {
      if (typeof onSubmit === "function") {
        try {
          setSubmitting(true);
          const {id, version} = survey;
          const result = await onSubmit({survey: id, version, answers: formValues})
          if (result?.clear === true) {
            // Clear localStorage on successful form submission
            localStorage.removeItem(`${surveyId}`);
            setFormValues({});
          }
        } finally {
          setSubmitting(false);
        }
      }
    }
  };

  return (
    <>
      {pages && pages[pageIndex].title && (<header>
        <h2 id="section-heading" className="text-lg font-bold tracking-tight lg:text-2xl">
          {t(pages[pageIndex].title)}
        </h2>
      </header>)}
      <form className="h-full w-full max-w-2xl md:w-4/5 flex flex-col gap-y-4">
        {pages && pages[pageIndex].questions.map(question => {
          switch (question.type) {
            case 'text':
              return (
                <TextInput
                  key={question.id}
                  question={question}
                  formValues={formValues}
                  onChange={handleInputChange}
                  errors={errors}
                />
              );
            case 'checkbox':
              return (
                <CheckboxGroup
                  key={question.id}
                  question={question}
                  options={question.options}
                  formValues={formValues}
                  onChange={handleInputChange}
                  errors={errors}
                />
              );
            case 'radio':
              return (
                <RadioGroup
                  key={question.id}
                  question={question}
                  options={question.options}
                  formValues={formValues}
                  onChange={handleInputChange}
                  errors={errors}
                />
              );
            case 'select':
              return (
                <DropDown
                  key={question.id}
                  question={question}
                  options={question.options}
                  formValues={formValues}
                  onChange={handleInputChange}
                  errors={errors}
                />
              );
            case 'agreement':
              return (
                <RadioGroup
                  key={question.id}
                  question={question}
                  options={[
                    {name: "I agree", value: "agree"},
                    {name: "I do not agree", value: "disagree"}
                  ]}
                  formValues={formValues}
                  onChange={handleInputChange}
                  errors={errors}
                  inline={true}
                >{question.options}</RadioGroup>
              );
            case 'textarea':
              return (
                <Textarea
                  key={question.id}
                  question={question}
                  formValues={formValues}
                  onChange={handleInputChange}
                  errors={errors}
                />
              );
            default:
              return null;
          }
        })}
        <footer className="flex justify-center gap-x-32">
          {pageIndex > 0 && (
            <button className="button button-white button-sm w-2/3 self-center" type="button" onClick={handlePrevious}
                    disabled={submitting}>
              Previous
            </button>
          )}
          {pageIndex < survey.pages.length - 1 ? (
            <button className="button button-white button-sm w-2/3 self-center" type="button" onClick={handleNext}
                    disabled={submitting}>
              Next
            </button>
          ) : (
            <button className="button button-purple button-sm w-2/3 self-center relative" type="button" onClick={handleSubmit}
                    disabled={submitting}>
              {survey.config?.submitText ?? "Submit"} {submitting &&
              <SvgIcon type="loading" classNames="animate-spin w-5 h-5 absolute top-4 right-4"/>}
            </button>
          )}
        </footer>
      </form>
    </>
  );
};

const TextInput = ({question, formValues, onChange, errors}) => {
  const {id, title, placeholder, required} = question;
  const value = formValues[id];
  return (
    <fieldset>
      <p className="block text-sm text-left font-medium leading-6">{title}{required && ' *'}</p>
      <input
        className={clsx("mt-2 input-text", errors[id] && "input-error")}
        type="text"
        id={id}
        name={id}
        value={value || ''}
        placeholder={placeholder}
        onChange={(event) => onChange({event, question})}
      />
      {errors[id] && <p className="mt-2 text-sm text-left text-red-600">{errors[id]}</p>}
    </fieldset>
  )
};

const CheckboxGroup = ({question, formValues, options, onChange, errors}) => {
  const {id, title, required} = question;
  return (
    <fieldset>
      <p className="block text-sm text-left font-medium leading-6">{title}{required && ' *'}</p>
      <div className="mt-2 space-y-1">
        {options.map((option, index) => {
          const uid = `${id}-option-${index}`;
          return (
            <CheckboxOption key={uid} uid={uid} question={question} options={options} option={option}
                            formValues={formValues} onChange={onChange} errors={errors}/>
          )
        })}
      </div>
      {errors[id] && <p className="mt-2 text-sm text-left text-red-600">{errors[id]}</p>}
    </fieldset>
  )
};

const CheckboxOption = ({uid, question, options, option, formValues, onChange, errors}) => {
  const {id} = question;
  const checkedItems = formValues[id];
  const isChecked = checkedItems ? checkedItems.includes(option.value) : false;

  return (
    <div key={option.value} className="relative flex items-center">
      <input type="checkbox"
             id={uid}
             name={id}
             checked={isChecked}
             onChange={(event) => onChange({event, question, options, option})}
             value={option.value}
             className="h-4 w-4 rounded border-gray-300 text-proof-purple dark:text-proof-purple"/>
      <label htmlFor={uid}
             className="ml-3 block text-sm text-left font-medium leading-6 select-none">{option.name}</label>
      <OptionTextInput key={option.input} question={question} option={option} isChecked={isChecked}
                       formValues={formValues} onChange={onChange} errors={errors}/>
    </div>
  )
};

const RadioGroup = ({children, question, formValues, options, inline, onChange, errors}) => {
  const {id, title, required} = question;
  return (
    <fieldset data-required={required}>
      <p className="block text-sm text-left font-medium leading-6">{title}{required && ' *'}</p>
      {children && (<ListItems items={children} />)}
      <div className={clsx("mt-2", inline && "flex justify-start items-center gap-10", !inline && "space-y-1")}>
        {options.map((option, index) => {
          const uid = `${id}-option-${index}`;
          return (
            <RadioOption key={uid} uid={uid} question={question} options={options} option={option}
                         formValues={formValues} onChange={onChange} errors={errors}/>
          )
        })}
      </div>
      {errors[id] && <p className="mt-2 text-sm text-left text-red-600">{errors[id]}</p>}
    </fieldset>
  )
};

const RadioOption = ({uid, question, options, option, formValues, onChange, errors}) => {
  const {id} = question;
  const value = formValues[id];
  const isChecked = value === option.value;

  return (
    <div key={option.value} className="flex items-center">
      <input type="radio"
             id={uid}
             name={id}
             checked={isChecked}
             onChange={(event) => onChange({event, question, options, option})}
             value={option.value}
             className="h-4 w-4 border-gray-300 text-proof-purple dark:text-proof-purple"/>
      <label htmlFor={uid}
             className="ml-3 block text-sm text-left font-medium leading-6 select-none">{option.name}</label>
      <OptionTextInput key={option.input} question={question} option={option} isChecked={isChecked}
                       formValues={formValues} onChange={onChange} errors={errors}/>
    </div>
  )
};

const OptionTextInput = ({question, option, isChecked, formValues, onChange, errors}) => {
  if (option.input && isChecked) {
    const id = option.input;
    const value = formValues[id];
    return (<div className="flex flex-col">
      <input type="text" name={id} value={value ?? ''}
             onChange={(event) => onChange({event, question, option})}
             className={clsx("ml-2 input-text", errors[id] && "input-error")}/>
      {errors[id] && <p className="ml-2 text-sm text-center text-red-600">{errors[id]}</p>}
    </div>);
  }

  return null;
}

const DropDown = ({question, formValues, options, inline, onChange, errors}) => {
  const {id, title, required} = question;
  const value = formValues[id] ?? "";

  return (
    <fieldset data-required={required}>
      <p className="block text-sm text-left font-medium leading-6">{title}{required && ' *'}</p>
      <div className={clsx("mt-2", inline && "flex justify-start items-center gap-10", !inline && "space-y-1")}>
        <select id={id} name={id} value={value} onChange={(event) => onChange({event, question})}
                className="mt-2 block select-default select-focus">
        {options.map((option, index) => {
          return (<option key={index} value={option.value}>{option.name}</option>)
        })}
        </select>
      </div>
      {errors[id] && <p className="mt-2 text-sm text-left text-red-600">{errors[id]}</p>}
    </fieldset>
  )
};


const Textarea = ({question, formValues, onChange, errors}) => {
  const {id, title, required} = question;
  const value = formValues[id];
  return (
    <fieldset data-required={required}>
      <p className="block text-sm text-left font-medium leading-6">{title}{required && ' *'}</p>
      <textarea rows="4" id={id} name={id} value={value || ''}
                onChange={(event) => onChange({event, question})}
                className={clsx("mt-2 input-text", errors[id] && "input-error")}></textarea>
      {errors[id] && <p className="mt-2 text-sm text-left text-red-600">{errors[id]}</p>}
    </fieldset>
  )
};

const ListItems = ({items}) => {
  return (<ul className="list-inside list-disc text-sm text-left font-medium">
    {items.map((item, index) => {
      return (<li key={index} className="my-4">{item.text}</li>)
    })}
  </ul>)
}
