import React from "react";
import PageLayout from "../components/PageLayout";
import {useTranslation} from "react-i18next";

export default function About() {
  const {t} = useTranslation()
  return (
    <PageLayout title="About">
      <h1 className="mb-2">{t('about.title')}</h1>
      <p className="text-secondary">{t('about.description.0')}</p>
      <p className="text-secondary py-3">{t('about.description.1')}</p>
      
      <h1 className="mt-6 mb-2">{t('about.authors')}</h1>
      <ul
        className="mx-auto grid grid-cols-1 gap-y-4">
        <li className="grid grid-cols-4 gap-4">
          <div className="h-full flex flex-col justify-start items-center mt-2">
            <img className="aspect-[1/1] w-60 h-fit rounded-2xl object-cover justify-center"
                 src="/images/LisaPorter-Profile.jpg"
                 alt={t("authors.lisa.title")}/>
          </div>
          <div className="col-span-3">
            <h3>{t("authors.lisa.title")}</h3>
            <p className="text-secondary">{t("authors.lisa.bio.0")}</p>
            <p className="mt-4 text-secondary">{t("authors.lisa.bio.1")}</p>
          </div>
        </li>
        <li className="grid grid-cols-4 gap-4">
          <div className="h-full flex flex-col justify-start items-center mt-2">
            <img className="aspect-[1/1] w-60 h-fit rounded-2xl object-cover justify-center"
                 src="/images/CaraSheekey-Profile.jpg"
                 alt={t("authors.cara.title")}/>
          </div>
          <div className="col-span-3">
            <h3>{t("authors.cara.title")}</h3>
            <p className="text-secondary">{t("authors.cara.bio.0")}</p>
          </div>
        </li>
        <li className="grid grid-cols-4 gap-4">
          <div className="h-full flex flex-col justify-start items-center mt-2">
            <img className="aspect-[1/1] w-60 h-fit rounded-2xl object-cover justify-center"
                 src="/images/EmmaBrady-Profile.jpg"
                 alt={t("authors.emma.title")}/>
          </div>
          <div className="col-span-3">
            <h3>{t("authors.emma.title")}</h3>
            <p className="text-secondary">{t("authors.emma.bio.0")}</p>
          </div>
        </li>
        <li className="grid grid-cols-4 gap-4">
          <div className="h-full flex flex-col justify-start items-center mt-2">
            <img className="aspect-[1/1] w-60 h-fit rounded-2xl object-cover justify-center"
                 src="/images/BreanneKearney-Profile.jpg"
                 alt={t("authors.breanne.title")}/>
          </div>
          <div className="col-span-3">
            <h3>{t("authors.breanne.title")}</h3>
            <p className="text-secondary">{t("authors.breanne.bio.0")}</p>
          </div>
        </li>
      </ul>
    </PageLayout>
  )
}