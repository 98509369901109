import {createPKCECodes} from "../util";

export default class SessionManager {
  #storage = null;

  constructor() {
    this.#storage = window.localStorage;
  }

  /**
   * Step 1: Creates a new oauth session, clearing any existing authentication tokens.
   *
   * @param preAuthUri
   */
  async newSession(preAuthUri = null) {
    preAuthUri = preAuthUri || window.location.pathname; // defaults to current uri
    const pkceCodes = await createPKCECodes();

    this.#storage.removeItem('auth')
    this.#storage.setItem('pkce', JSON.stringify(pkceCodes))
    this.#storage.setItem('preAuthUri', preAuthUri)

    const {codeChallenge, codeChallengeMethod} = pkceCodes;
    return {codeChallenge, codeChallengeMethod};
  }

  /**
   * Step 2: Gets stored pkce value and clears pending authentication attempt
   * @returns {*}
   */
  getPkceCodeVerifier() {
    const {codeVerifier} = this.#getState().pkce;
    this.#storage.removeItem('pkce');
    return codeVerifier;
  }

  /**
   * Step 3: Store validated session token
   * @param token
   */
  storeSession(token) {
    this.#storage.setItem('auth', JSON.stringify(token));
  }

  /**
   * Returns copy of current session data
   *
   * @returns {any|null}
   */
  getSessionData() {
    return JSON.parse(JSON.stringify(this.#getState()));
  }

  /**
   * Resets back to a clear state, for example on logout
   */
  clearSession() {
    this.#storage.removeItem('auth')
    this.#storage.removeItem('pkce')
    this.#storage.removeItem('preAuthUri')
  }

  #getState() {
    const authTokens = JSON.parse(this.#storage.getItem('auth') || "{}");
    const pkce = JSON.parse(this.#storage.getItem('pkce') || "{}");
    const preAuthUri = this.#storage.getItem('preAuthUri');
    return {authTokens, preAuthUri, pkce};
  }
}
