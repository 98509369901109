export const contactForm = {
  id: 'site-enquiry',
  version: "v1.0.0",
  config: {
    submitText: "Send message",
  },
  pages: [
    {
      id: "personal-details",
      questions: [
        {
          id: "name",
          required: true,
          title: "What is your name?",
          default: "userInfo:name",
          label: "Name",
          placeholder: "",
          type: "text"
        },
        {
          id: "type",
          required: true,
          title: "Enquiry Type?",
          label: "Name",
          default: "general-enquiry",
          placeholder: "",
          type: "select",
          options: [
            {name: "General Enquiry", value: "general-enquiry"},
            {name: "Supervision Request", value: "supervision-request"},
            {name: "Feedback and Suggestions", value: "general-feedback"},
            {name: "Account or Login Issue", value: "account-issue"},
            {name: "Website Issue", value: "website-issue"},
            {name: "Other", value: "other"},
          ]
        },
        {
          id: "email",
          required: true,
          title: "Preferred contact email?",
          default: "userInfo:email",
          label: "Email",
          placeholder: "",
          type: "text"
        },
        {
          id: "message",
          required: true,
          title: "Message",
          label: "Message",
          type: "textarea",
        },
      ]
    },
  ]
};

