import './index.css';
import './i18n';

import {AuthProvider} from "./services/auth/CognitoAuthProvider";
import theme from "./services/ThemeService";
import config from './aws-config.json';
import ReactDOM from 'react-dom/client';
import React from 'react';
import App from './App';

theme.refresh();
const isDev = ("development" === process.env.NODE_ENV);

const Sentry = require("@sentry/react");
Sentry.init({
  environment: isDev ? "development" : "production",
  dsn: "https://1d0c3bb3cf52bd85683c085d36ab3183@o4507429107662848.ingest.us.sentry.io/4507429110808576",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1,
  tracePropagationTargets: ["localhost", /^https:\/\/api\.proof-online\.net/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// read configuration file
const apiConfig = {
  provider: config.cognito.provider || 'https://sandbox.auth.ap-southeast-2.amazoncognito.com/oauth2',
  redirectUri: isDev ? `${window.location.origin}/oauth/authorize` : config.oauth.redirectUri,
  clientId: config.cognito.clientId || 'client-id-missing',
  scopes: config.cognito.scope || ['openid', 'profile'],
  clientSecret: config.cognito.secret || undefined,
  autoRefresh: true
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider apiConfig={apiConfig}>
      <App/>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// import reportWebVitals from './reportWebVitals';
// reportWebVitals(console.log);
