import * as Sentry from "@sentry/react";

class ErrorService {
  captureException(error, source, context = {}) {
    console.error(`Exception: ${error.message || error} (${source})`, error);
    Sentry.setContext("source", {
      source: source,
      ...context
    });
    Sentry.captureException(error);
  }

  // used to ensure react only creates one auth service
  static #instance;

  static getInstance(session) {
    if (!this.#instance) {
      this.#instance = new ErrorService();
    }
    return this.#instance;
  }
}

/**
 * @type {ErrorService}
 */
export const errorService = ErrorService.getInstance();
