import React, {useState} from "react";
import {useApi} from "../services/ApiService";
import {Trans, useTranslation} from "react-i18next";

import DownloadButton from "../components/DownloadButton";
import PageLayout from "../components/PageLayout";
import SvgIcon from "../components/SvgIcon";

export default function Resources() {
  const {t} = useTranslation();
  const [error, setError] = useState(false);
  const api = useApi();

  const fileDownload = async (fileName) => {
    try {
      setError(false);
      // get new signed s3 urls from backend
      const response = await api.resourcesGet();
      if (response.success) {
        const {data} = response; 
        const url = new URL(data[fileName]);
        
        // Create a link to trigger a file download
        const link = document.createElement('a');
        link.href = url.toString();
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (error) {
      console.error("Error fetching resources for download:", error);
      setError(true);
    }
  };

  return (
    <PageLayout title="Resources">
      <h1 className="mb-2">{t("resources.title")}</h1>
      <p className="text-secondary">{t('resources.description.0')}</p>
      <p className="text-secondary my-4">{t('resources.description.1')}</p>
      <ul className="mt-4 divide-y divide-gray-100 rounded-md border border-gray-200 mx-auto max-w-screen-md">
        <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
          <div className="flex w-0 flex-1 items-center">
            <SvgIcon type="attachment"/>
            <div className="ml-4 flex min-w-0 flex-1 flex-col gap-1">
              <span className="truncate font-medium">{t("resources.manualButton")}</span>
              <span className="truncate text-sm font-light">{t("resources.manualDescription")}</span>
            </div>
          </div>
          <div className="ml-4 flex-shrink-0">
            <DownloadButton file="proofManual" onClick={fileDownload}>{t("resources.buttonText")}</DownloadButton>
          </div>
        </li>
        <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
          <div className="flex w-0 flex-1 items-center">
            <SvgIcon type="attachment"/>
            <div className="ml-4 flex flex-col min-w-0 flex-1 gap-1">
              <span className="truncate font-medium">{t("resources.reflectionButton")}</span>
              <span className="truncate text-sm font-light">{t("resources.reflectionDescription")}</span>
            </div>
          </div>
          <div className="ml-4 flex-shrink-0">
            <DownloadButton file="reflectionSheet" onClick={fileDownload}>{t("resources.buttonText")}</DownloadButton>
          </div>
        </li>
        <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
          <div className="flex w-0 flex-1 items-center">
            <SvgIcon type="attachment"/>
            <div className="ml-4 flex flex-col min-w-0 flex-1 gap-1">
              <span className="truncate font-medium">{t("resources.domainButton")}</span>
              <span className="truncate text-sm font-light">{t("resources.domainDescription")}</span>
            </div>
          </div>
          <div className="ml-4 flex-shrink-0">
            <DownloadButton file="domainDescriptors" onClick={fileDownload}>{t("resources.buttonText")}</DownloadButton>
          </div>
        </li>
      </ul>
      <p className="text-secondary my-4">{t('resources.description.2')}</p>
      <p className="text-secondary my-4">{t('resources.description.3')}</p>
      <p className="text-secondary my-4">
        <Trans i18nKey="resources.description.4">
          If you are interested in utilising the PROOF framework but feel that you need further training or explanation, please <a className="font-medium underline hover:opacity-50" href="/contact">contact the authors</a> to request a supervision session.
      </Trans>
    </p>
      <p className="text-secondary my-4">{t('resources.description.5')}</p>
      {error && (
        <div className="mt-2 text-sm text-center text-red-800">
          <Trans i18nKey="resources.downloadError">
            An error occurred please <a className="font-medium underline hover:opacity-50" href="/resources">reload the
            page</a> and try again
          </Trans>
        </div>)}
    </PageLayout>
  )
}