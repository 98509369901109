import React from "react";
import {jsonToCsv} from "../services/util";

const ExportCsvComponent = ({ data }) => {
    // Utility function to trigger download of CSV
    const downloadCsv = () => {
        const date = new Date();
        const csv = jsonToCsv(data);
        const blob = new Blob([csv], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", `users.${date.getTime()}.csv`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <button className="button button-purple button-sm" disabled={data.length === 0} onClick={downloadCsv}>Export</button>
    );
};

export default ExportCsvComponent;