import {useAuth} from "../services/auth/CognitoAuthContext";
import {checkUserPermission} from "../services/util";

const UserPermission = ({groups = [], children}) => {
  const {authenticated, userInfo} = useAuth();
  const isAllowed = checkUserPermission(authenticated, groups, userInfo);
  return isAllowed ? children : null;
}


export default UserPermission;
