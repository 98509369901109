import {AuthContext, initialAuthState, createState} from "./CognitoAuthContext";
import {useCallback, useEffect, useMemo, useState} from "react";
import {AuthService} from "./CognitoAuthService";

export const AuthProvider = ({apiConfig, children}) => {
  // Memoize authService to prevent re-creation on every render
  const authService = useMemo(() => AuthService.getInstance(apiConfig), [apiConfig]);
  
  const [initialised, setInitialised] = useState(false);
  const [state, setState] = useState(initialAuthState);
  
  useEffect(() => {
    // setup initial state
    const sessionData = authService.getSession();
    const state = createState(sessionData);
    setState(state);

    // Register listener
    authService.setSessionListener((session) => {
      const state = createState(session);
      setState(state);
    });
    
    setInitialised(true);
  }, []); // eslint-disable-line

  // Wrappers around auth service allowing us to easily expose these to the front end and perform other actions
  const verify = useCallback(async (code) => {
    return await authService.verify(code);
  }, [authService]);

  const refresh = useCallback(async () => {
    return await authService.refresh();
  }, [authService]);

  const login = useCallback(async () => {
    return await authService.authorize(false);
  }, [authService]);

  const register = useCallback(async () => {
    return await authService.authorize(true);
  }, [authService]);

  const logout = useCallback(async () => {
    // todo: decide when we should do a full logout
    return await authService.logout(false);
  }, [authService]);
  
  return (
    <AuthContext.Provider value={{...state, login, logout, register, verify, refresh, initialised}}>
      {children}
    </AuthContext.Provider>
  )
}
