export const termsAndConditions = {
  id: 'terms-and-conditions',
  version: "v1.0.0",
  pages: [
    {
      id: "personal-details",
      title: "User Survey",
      questions: [
        {
          id: "name",
          required: true,
          title: "What is your name?",
          default: "userInfo:name",
          label: "Name",
          placeholder: "",
          type: "text"
        },
        {
          id: "preferred-email",
          required: true,
          title: "Preferred contact email?",
          default: "userInfo:email",
          label: "Email",
          placeholder: "",
          type: "text"
        },
        {
          id: "country",
          required: true,
          title: "In what country do you live?",
          label: "Country",
          placeholder: "",
          type: "text"
        },
        {
          id: "profession",
          required: true,
          title: "What is your professional qualification?",
          label: "Workplace",
          type: "radio",
          options: [
            {name: "Occupational Therapist", value: "occupational-therapist"},
            {name: "Speech and Language Pathologist", value: "speech-pathologist"},
            {name: "Psychologist", value: "psychologist"},
            {name: "Educator", value: "educator"},
            {name: "Other", value: "other", input: "profession-other"},
          ]
        },
        {
          id: "primary-workplace",
          required: true,
          title: "What is your primary work setting?",
          label: "Workplace",
          type: "checkbox",
          options: [
            {name: "Pediatric Clinic", value: "pediatric-clinic"},
            {name: "Community Based Organization", value: "community-based-organization"},
            {name: "Early Intervention", value: "early-intervention"},
            {name: "School Setting", value: "school-setting"},
            {name: "Academia", value: "academia"},
            {name: "Other", value: "other", input: "primary-workplace-other"},
          ]
        },
        {
          id: "age-ranges",
          required: true,
          title: "What age ranges do you serve?",
          label: "Age Ranges",
          type: "checkbox",
          options: [
            {name: "Birth to 3 years", value: "0-3"},
            {name: "4-6 years", value: "4-6"},
            {name: "7-12 years", value: "7-12"},
            {name: "13-17 years", value: "13-17"},
            {name: "18+ years", value: "18+"},
          ]
        },
        {
          id: "experience",
          required: true,
          title: "How many years professional experience do you have?",
          label: "Experience",
          type: "radio",
          options: [
            {name: "5 years or less", value: "0-5"},
            {name: "6-10 years", value: "6-10"},
            {name: "11-15 years", value: "11-15"},
            {name: "16-20 years", value: "16-20"},
            {name: "20+ years", value: "20+"},
          ]
        },
        {
          id: "education",
          title: "What post graduate training or certifications do you hold?",
          label: "Education",
          type: "textarea",
        },
        {
          id: "referral-source",
          required: false,
          title: "How did you hear about PROOF?",
          label: "Referral Source",
          placeholder: "",
          type: "text"
        },
      ]
    },
    {
      id: "user-agreement",
      title: "User Agreement",
      questions: [
        {
          id: "user-agreement",
          type: "agreement",
          title: "By completing this form, I understand:",
          required: true,
          options: [
            {text: "PROOF was designed for use by qualified/registered Occupational Therapy practitioners. I understand that I may require additional support/training in using PROOF if I am not an Occupational Therapist."},
            {text: "PROOF tools and resources are documents that may be used without a fee."},
            {text: "PROOF tools, user manual, and related files found on this site may not be adapted, translated, modified, linked to, or sold without express permission from the authors"},
            {text: "I will obtain permission from the authors before using these documents for educational purposes"},
            {text: "I may NOT reproduce these documents or share related files with other professionals without permission"},
            {text: "I may reproduce these documents as needed for professional use with individuals served in practice"},
            {text: "I may be contacted by the authors in the future for possible participation in research pertaining to the clinical use of PROOF"},
          ]
        },
      ]
    }
  ]
};